import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/particular.css";

function Particular() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <div className="term-h1">
            <h1>特定商取引法に基づく表記</h1>
          </div>
          <div class="rte">
            <div class="ko_none">
              <div class="scrollable-wrapper">
                <table
                  border="0"
                  bgcolor="#999999"
                  cellpadding="9"
                  cellspacing="1"
                  height="1608"
                >
                  <tbody>
                    <tr>
                      <th bgcolor="#eeeeee">販売社名</th>
                      <td bgcolor="#ffffff">株式会社クロダシステムズ</td>
                    </tr>
                    <tr>
                      <th bgcolor="#eeeeee">運営統括責任者</th>
                      <td bgcolor="#ffffff">黒田　裕也</td>
                    </tr>
                    <tr>
                      <th bgcolor="#eeeeee">所在地</th>
                      <td bgcolor="#ffffff">
                        <span>兵庫県神戸市西区北別府5丁目15-2</span>
                      </td>
                    </tr>
                    <tr>
                      <th bgcolor="#eeeeee">電話番号</th>
                      <td bgcolor="#ffffff">078-915-7574</td>
                    </tr>
                    <tr>
                      <th bgcolor="#eeeeee">
                        <span>インボイス制度</span>
                        <br />
                        <span>適格請求書発行事業者登録番号</span>
                      </th>
                      <td bgcolor="#ffffff">T3-1400-0109-1864</td>
                    </tr>
                    <tr>
                      <th bgcolor="#eeeeee">メールアドレス</th>
                      <td bgcolor="#ffffff">work@hitacle.shop</td>
                    </tr>
                    <tr>
                      <th bgcolor="#eeeeee">販売URL</th>
                      <td bgcolor="#ffffff">https://hitacle.shop/</td>
                    </tr>
                    <tr>
                      <th bgcolor="#eeeeee">お支払い方法</th>
                      <td bgcolor="#ffffff">
                        <p>
                          ・クレジットカード
                          <br />
                          ・Amazon Pay
                          <br />
                          ・Google<span data-mce-fragment="1">&nbsp;Pay</span>
                          <br />
                          ・Apple Pay
                          <br />
                          ・Shop Pay
                          <br />
                          ・楽天ペイ
                          <br />
                          ・PayPay
                          <br />
                          ・メルペイ
                          <br />
                          ・LINEペイ
                          <br />
                          ・d払い
                          <br />
                          ・コンビニ決済
                          <br />
                          ご購入金額の上限は30万円（手数料込みの税込み金額）となります。
                        </p>
                        <ol>
                          <li>セブンイレブン</li>
                          <li>ローソン</li>
                          <li>ファミリーマート</li>
                          <li>ミニストップ</li>
                          <li>デイリーヤマザキ</li>
                        </ol>
                        <p>・銀行振込</p>
                        <p>&nbsp;</p>
                      </td>
                    </tr>
                    <tr>
                      <th bgcolor="#eeeeee">
                        商品代金以外の
                        <br />
                        必要金額
                      </th>
                      <td bgcolor="#ffffff">
                        <p class="Typography--paragraph">
                          ・コンビニエンスストア支払い
                          <br />
                          手数料として190円がお支払いの際に追加されます。
                        </p>
                        <p class="Typography--paragraph">
                          ・配送料
                          <br />
                          配送料金：北海道990円、沖縄2,700円、その他750円（税込）です。
                          <br />
                          <br />
                          税込み￥22,000円以上の場合は、配送料無料です。
                          <br />
                          （配送料金に変更がある場合は、別途サイト上やメールマガジンでお知らせいたします。）
                        </p>
                        <p class="Typography--paragraph">&nbsp;</p>
                      </td>
                    </tr>
                    <tr>
                      <th bgcolor="#eeeeee">販売数量</th>
                      <td bgcolor="#ffffff">1個から</td>
                    </tr>
                    <tr>
                      <th bgcolor="#eeeeee">お支払い有効期限</th>
                      <td bgcolor="#ffffff">
                        3日以内にお願いいたします。
                        <br />
                        3日間入金がない場合は、キャンセルとさせていただきます。
                      </td>
                    </tr>
                    <tr>
                      <th bgcolor="#eeeeee">商品引渡し時期</th>
                      <td bgcolor="#ffffff">
                        指定日が無ければ入金確認後2営業日以内で発送致します。※ご予約商品は除く
                      </td>
                    </tr>
                    <tr>
                      <th bgcolor="#eeeeee">商品引渡し方法</th>
                      <td bgcolor="#ffffff">
                        当方にて手配後、運送会社による配送
                      </td>
                    </tr>
                    <tr>
                      <th bgcolor="#eeeeee">返品について</th>
                      <td bgcolor="#ffffff">
                        「不良品・当社の商品の間違い」の場合は当社が負担いたします。
                        <br />
                        配送途中の破損などの事故がございましたら、弊社までご連絡下さい。
                        <br />
                        送料・手数料ともに弊社負担で早急に新品をご送付致します。
                        <br />
                        <br />
                        【返品対象】
                        <br />
                        「不良品・当社の商品の間違い」の場合
                        <br />
                        <br />
                        【返品時期】
                        <br />
                        ご購入後30日以内にご連絡があった場合に返金可能となります。
                        <br />
                        <br />
                        【返品方法】
                        <br />
                        メールにて返金要請してください。
                        <br />
                        返品確認後2日以内にご購入代金を指定の口座へお振込みいたします。
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Particular;
